import { Modal } from "antd";
import dayjs from "dayjs";
import _ from "lodash";

export const modalError = (title, content) => {
    Modal.error({
        title: title,
        content: (
            <div>{content}</div>
        )
    });
}

export const modalSuccess = (title, content) => {
    Modal.success({
        title: title,
        content: (
            <div>{content}</div>
        )
    })
}

export const objectToQuery = (uri, obj) => {
    const flattenObject = (prefix, value) => {
        if (_.isObject(value) && !Array.isArray(value)) {
            return Object.keys(value)
                .flatMap(key => flattenObject(`${prefix}[${encodeURIComponent(key)}]`, value[key]))
                .join('&');
        }
        return `${prefix}=${encodeURIComponent(value)}`;
    };

    const cleanedObj = _.omitBy(obj, value => value === null || value === undefined || value === '' || value === false || Number.isNaN(value) || (Array.isArray(value) && value.length === 0));

    const queryString = Object.keys(cleanedObj)
        .flatMap(key => {
            const value = cleanedObj[key];

            if (Array.isArray(value)) {
                return value.map(item => {
                    if (_.isObject(item)) {
                        return Object.keys(item)
                            .map(subKey => `${encodeURIComponent(key)}[${encodeURIComponent(subKey)}]=${encodeURIComponent(item[subKey])}`)
                            .join('&');
                    }
                    return `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`;
                });
            } else if (_.isObject(value)) {
                return flattenObject(encodeURIComponent(key), value);
            }

            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join('&');

    return queryString ? `${uri}?${queryString}` : uri;
};


export const formatCurrency = (value) => {
    let newValue = parseFloat(value);
    if (isNaN(newValue)) {
        return `$ 0`;
    }

    return newValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    }).replace("$", "$ ");
}

export const generateDiscountText = (discounts) => {
    const filtered = discounts.filter(d => d.percentage >= 0);

    if (filtered.length === 0) return "Receives 0% off Pharmacy, 0% off BPL, and 0% off Treatments";

    const mapped = filtered.map(d => `${d.percentage}% off ${d.type === "Service" ? "Service Treatments" : d.type === "Supplement" ? "BPL" : d.type}`);

    return `Receives ${mapped.join(", ").replace(/,([^,]*)$/, ", and$1")}`;
}

export const getMaxDiscountText = (attributes, type) => {
    if(!attributes || attributes.length === 0) {
        return ""
    }
    let maxDiscounts = {};

    attributes.forEach(attr => {
        attr.discounts.forEach(discount => {
            if (!maxDiscounts[discount.type] || discount.percentage > maxDiscounts[discount.type]) {
                maxDiscounts[discount.type] = discount.percentage;
            }
        });
    });

    if (type) {
        return maxDiscounts[type] > 0 ? `This patient received ${maxDiscounts[type]}% off ${type === "Service" ? "Service Treatments" : type === "Supplement" ? "BPL" : type}` : "";
    }

    // Convert maxDiscounts into the required format for generateDiscountText
    const discountsArray = Object.entries(maxDiscounts).map(([key, percentage]) => ({
        type: key,
        percentage
    }));

    return generateDiscountText(discountsArray);
}

export const roundToTwoDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
}

export const getShortForm = (str) => {
    return str
        .match(/[A-Za-z]+/g) // Extract only words (ignoring '&' and other non-letter characters)
        .map(word => word[0].toUpperCase()) // Get first letter of each word
        .join(""); // Join letters
}

export const convertDatesRecursively = (obj) => {
    if (!obj || typeof obj !== "object") return obj;

    if (Array.isArray(obj)) {
        return obj.map(convertDatesRecursively);
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (typeof value === "string" && /Date$/i.test(key) && dayjs(value).isValid()) {
            acc[key] = dayjs(value);
        } else if (typeof value === "object") {
            acc[key] = convertDatesRecursively(value);
        } else {
            acc[key] = value;
        }
        return acc;
    }, {});
};