import { Button, Card } from "react-bootstrap";
import NavItemNavigation from "../../components/navigation/NavItemNavigation";
import React, { useEffect, useMemo, useRef, useState } from "react";
import CardComponent from "../../components/card/CardComponent";
import { useSelector } from "react-redux";
import InputDate from "../../components/container/inputField/inputDate";
import timeUtils from "../../utils/timeUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckRoleType } from "../../utils/checkRole";
import API from '../../utils/request'
import { API_ENDPOINT_LOCATION } from "../../config/api";
import { Input, message } from "antd";
import { EVENT_LIST_URL, REQUEST_EVENT_LIST_URL } from "../../config/urls";
import Paginationcomponent from "../../components/table/Paginationcomponent";
import LoadingOverlay from "../../components/container/loading/LoadingOverlay";
import { CiSearch } from "react-icons/ci";
import { CenterDetail, CenterDetails, CenterObject } from "../../utils/checkCenterLocation";
import { storageKeys } from "../../constants/storage";
import * as Storage from '../../utils/storage';
import MessageModalMain from "../../components/container/modal/modalMessage/messageModalMain";
import SelectMultiCustom from "../../components/select/SelectMultiCustom";
import ExportEventToExcel from "../../utils/exportEventToExcel";
import { objectToQuery } from "../../utils/utils";
import RequestEventCardComponent from "./components/RequestEventCardComponent";
import CopySVG from "../../assets/svgicons/copySVG";

const RequestEventPage = () => {
    const [currentPositionKey, setCurrentPositionKey] = React.useState("new");
    const [data, setData] = useState([])
    const navigate = useNavigate();
    const user = useSelector((state) => state.authReducer.user);
    var users = Storage.getJson(storageKeys.USER);
    let userUse = user ? user : users
    const positionSteps = useMemo(() => [
        { name: "New", key: "new" },
        { name: "Rejected", key: "rejected" },
        { name: "Approved", key: "approved" },
    ], [userUse]);
    const location = useLocation()
    const [centerId, setCenterId] = useState([]);
    const [loading, setLoading] = useState(false);
    const messageModalRef = useRef('rememberMe');
    const [total, setTotal] = useState(0)
    const [isCall, setIsCall] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10
    })
    const [keyword, setKeyword] = useState("")
    const [searchParams, setSearchParams] = useState({
        "startDate": "",
        "endDate": "",
        "status": "new",
    })
    const [centerData, setCenterData] = useState([])

    useEffect(() => {
        if (centerData.length > 0) {
            if (userUse && userUse?.role === "center_staff") {
                setCenterId([CenterObject(userUse?.centerId, centerData)])
            } else {
                if (location && location.state && location.state.centerId) {
                    setCenterId([CenterObject(location.state.centerId, centerData)])
                } else {
                    setCenterId([]);
                }
            }
        }
    }, [user, userUse, centerData])


    const getDataCenter = async () => {
        setLoading(true)
        try {
            API.post(API_ENDPOINT_LOCATION, {
                "serviceName": "location",
                "serviceType": "getSavableLocations"
            })
                .then((res) => {
                    if (res.status === 200) {
                        setCenterData(res.locations ? res.locations.map(it => ({
                            ...it,
                            label: `CSL Plasma, ${it.name}`,
                            value: it.locationID
                        })) : [])
                    }
                })
                .catch((err) => {
                })
        } catch (error) {
        }
    }
    useEffect(() => {
        getDataCenter()
    }, []);

    const getSearchPaylaod = () => {
        return {
            ...keyword ? { "keyword": keyword ? keyword.trim() : "" } : {},
            ...searchParams?.endDate ? { "endDate": searchParams?.endDate } : {},
            ...searchParams?.startDate ? { "startDate": searchParams?.startDate } : {},
            ...centerId.length > 0 ? { "requestCenter": centerId.map((i) => JSON.parse(i).locationID) } : {},
            "status": currentPositionKey,
            "limit": pagination.pageSize,
            "page": pagination.current
        };
    }

    const getDataEventList = async () => {
        try {
            setLoading(true)
            API.post(REQUEST_EVENT_LIST_URL, getSearchPaylaod())
                .then((res) => {
                    if (res.status === 200) {
                        setData(res.data ? res.data.map(it => ({ ...it, })) : [])
                        setTotal(res.totalCount ? res.totalCount : 0)
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                })
        } catch (error) {
            setLoading(false)
            message.error('Internal Server Error!')
        }
    }
    useEffect(() => {
        if (centerData.length > 0) {
            getDataEventList()
        }
    }, [centerId, currentPositionKey, searchParams, JSON.stringify(pagination), isCall]);
    const handleKeyEnter = (e, isSearch) => {
        if (e.key === 'Enter') {
            setSearchParams({ ...searchParams, eventName: keyword })
        }
        else if (isSearch) {
            setSearchParams({ ...searchParams, eventName: keyword })
        }
    };

    const handleDetail = (item) => {
        navigate(`/request-event/${item.requestId}`)
    }

    const handleCopyLink = (link) => {
        const fullUrl = link; // Append ID as a query parameter
        navigator.clipboard.writeText(fullUrl).then(() => {
            message.success("Copied to clipboard!");
        }).catch(err => {
            console.error("Failed to copy:", err);
        });
    }

    let dataNew = data.map(it => ({
        ...it,
        centerName: it && it?.intakeInformation?.requestCenter && it?.intakeInformation?.requestCenter.map(ct => CenterDetail(ct, centerData))
    }))

    return <div className="width-100 m-auto h-100 pt-2-">
        <div className="row pt-3- pb-3">
            <div className="col-8 d-flex align-items-center">
                <span
                    className="d-flex align-items-center me-5">
                    <span className="dashboardH3">Event Requests</span>
                </span>
            </div>

            <div className="col-4 d-flex align-items-center justify-content-end">
                <Button
                    onClick={() => handleCopyLink(`${window.location.href}/form`)}
                    variant="primary" size="sm" style={{ background: "none", color: "#0d6efd" }}>
                    <CopySVG width={"1.3rem"} height={"1.3rem"} />
                    <span className='ps-1'>Copy Form Link</span>
                </Button>
            </div>
        </div>

        <div className="w-100 h-100- mt-2"
        >
            <Card className="h-100 card-cus-" style={{ minHeight: "33rem" }}>
                <Card.Body className="ps-0 pe-0">

                    <div style={{ marginBottom: "1rem" }}>
                        <NavItemNavigation
                            currentKey={currentPositionKey}
                            setCurrentKey={(value) => {
                                setCurrentPositionKey(value)
                                setKeyword("")
                                setSearchParams({
                                    "eventName": "",
                                    "startDate": "",
                                    "endDate": "",
                                    "status": "active", //["active", "inactive", "deleted", "expired"]
                                    "centerId": "",
                                    "sortField": "_id",
                                    "sort": "desc",
                                })
                            }}
                            currentPage={currentPositionKey}
                            setCurrentPage={(value) => {
                                setCurrentPositionKey(value)
                                setKeyword("")
                                setSearchParams({
                                    "eventName": "",
                                    "startDate": "",
                                    "endDate": "",
                                    "status": "active", //["active", "inactive", "deleted", "expired"]
                                    "centerId": "",
                                    "sortField": "_id",
                                    "sort": "desc",
                                })
                            }}
                            steps={positionSteps}
                            marginRight="1.69rem"
                            marginLeft="1rem"
                        />
                    </div>

                    <div className="w-100 ps-3 pe-3">

                        <div className="w-100 h-100- mt-2" >
                            <div className="row gx-4 gap-2 gap-md-0">
                                <div className="col-sm-6 col-md-3 col-lg-3 mt-1">
                                    <Input
                                        size="large"
                                        style={{ maxWidth: "500px" }}
                                        placeholder="Search"
                                        value={keyword}
                                        height={"2.4rem"}
                                        styles={{
                                            height: "2.4rem"
                                        }}
                                        onChange={(e) => setKeyword(e.target.value)}
                                        suffix={<CiSearch onClick={() => handleKeyEnter('', true)} role="button" />}
                                        onKeyDown={handleKeyEnter}
                                    />
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 mt-1">
                                    {CheckRoleType(user && user?.role) ?
                                        <SelectMultiCustom
                                            onSelected={(value) => {
                                                setCenterId([...value]);
                                                setPagination({
                                                    ...pagination,
                                                    current: 1
                                                })
                                            }}
                                            currentSelect={centerId}
                                            className="w-100"
                                            centerData={centerData}
                                            minWidth="100%"
                                            height={"2.6rem"}
                                            size={1}
                                            maxTagCount="responsive"
                                        />
                                        : ""}
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 mt-1'>
                                    <div className="row  gap-2 gap-md-0 gx-4 justify-content-around justify-content-md-start">
                                        <div className='col-5 col-sm-5 col-md-3 col-lg-3'>
                                            <InputDate
                                                value={searchParams.startDate}
                                                onChange={(date) => {
                                                    setSearchParams({ ...searchParams, startDate: date ? timeUtils.formatData(date) : "" })
                                                }}
                                                id="startDate"
                                                placeholder='Start Date'
                                                max={searchParams.endDate}
                                            />
                                        </div>
                                        <div className='col-5 col-sm-5 col-md-3 col-lg-3'>
                                            <InputDate
                                                value={searchParams.endDate}
                                                onChange={(date) => {
                                                    setSearchParams({ ...searchParams, endDate: date ? timeUtils.formatData(date) : "" })
                                                }}
                                                id="endDate"
                                                placeholder='End Date'
                                                min={searchParams.startDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="w-10">
                            {
                                dataNew && dataNew.map((it, i) => (
                                    <div className="mt-3">
                                        <RequestEventCardComponent
                                            data={it}
                                            handleDetail={handleDetail}
                                            handleCopyLink={handleCopyLink}
                                            centerData={centerData}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <div className="w-100 mt-3">
                            {total ? <Paginationcomponent total={total} pagination={pagination} setPagination={setPagination} /> : ""}
                        </div>
                    </div>

                </Card.Body>
            </Card>
        </div>
        {loading ? <LoadingOverlay /> : null}
        <MessageModalMain textCentered ref={messageModalRef} />
    </div>
}
export default RequestEventPage;