import { Input } from 'antd'
import React from 'react'

export default function InputCurrency(props) {
    const { height, disabled, value, onChange, placeholder } = props;

    const handleInputNumberFormat = (parseValue) => {
        const newValue = String(parseValue ?? value).replace(/[^0-9]/g, '');

        let intValue = parseInt(newValue);
        if (isNaN(intValue)) {
            return onChange && onChange("");
        }

        let formatValue = intValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        }).replace("$", "$ ");

        return onChange && onChange(formatValue);
    }

    return (
        <Input
            onInput={(event) => handleInputNumberFormat(event.target?.value)}
            onBlur={() => handleInputNumberFormat()}
            value={value}
            disabled={disabled}
            style={{ height: height }}
            className='w-100'
            placeholder={placeholder}
        />
    )
}
