import React, { useEffect, useState } from 'react'
import { Avatar, Dropdown, Layout, Menu, Typography } from 'antd';
import CSLLogo from '../../assets/images/csl_logo_4.png'
import './layout.css'
import { Nav, Navbar } from 'react-bootstrap';
import { RiArrowDownSFill, RiArrowDropDownLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineAccountCircle } from 'react-icons/md';
import * as Storage from '../../utils/storage';
import { storageKeys } from '../../constants/storage';
import { useSelector } from 'react-redux';
import { CheckRoleType, CheckRoleTypeText } from '../../utils/checkRole';
import FooterComponent from './footerComponent';

const { Content } = Layout

const CslLayout = (props) => {
    const user = useSelector(state => state.authReducer.user);
    var tokens = Storage.getString(storageKeys.TOKEN);
    const tokenR = useSelector((state) => state.authReducer.token);
    const token = tokens ? tokens : tokenR;
    const paths = window.location.pathname;
    const [path, setpath] = useState(paths)
  
    let location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setpath(paths)
    }, [location])

    let navItems = [
        {
            label: 'Dashboard',
            key: 'dashboard',
            path: '/dashboard'
        },
        {
            label: 'Event',
            key: 'event',
            path: '/event'
        },
        
    ];
    if (CheckRoleType(user && user.role)) {
        navItems.push({
            label: 'User Management',
            key: 'user-management',
            path: '/user-management'
        })

        navItems.push({
            label: 'Request',
            key: 'request',
            path: '/request-event'
        });
    }

    const items = (
        <Menu>
          <Menu.Item onClick={() => { logoutHandler(); }} >Log Out</Menu.Item>
          <Menu.Item onClick={() => { changePasswordHandler(); }} >Change Password</Menu.Item>
        </Menu>
      );

    const logoutHandler = () => {
        localStorage.clear();
        window.location.href = "/login";
    };
    const changePasswordHandler = () => {
        navigate(`/change/password`)
    };

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary nav-header" style={{zIndex: 50}}>
                <div className='csl-confined-header csl-header'>
                    <div className='d-flex flex-row align-items-center h-100'>
                        <img className='csl-logo' src={CSLLogo} role="button" onClick={() => navigate(token ? '/dashboard' : "/login")} />
                        <Nav className="csl-navigation d-lg-flex d-none">
                            {
                                token && navItems.map((item, index) => <Nav.Link onClick={() => navigate(`${item.path}`)} className={`csl-nav ${path.startsWith(item.path) ? 'active' : ''}`}>{item.label}</Nav.Link>)
                            }
                        </Nav>
                    </div>
                    <div className='d-lg-flex align-items-center d-none gap-3'>
                        {token ? <>
                        <Typography className='user-header'>
                            <Typography.Text className='user-name'>
                                {CheckRoleTypeText(user && user.role).type} {user && user.fullName}
                            </Typography.Text>
                            <Typography.Text className='user-role' style={{color: "rgb(13, 110, 253)"}}>
                            {CheckRoleTypeText(user && user.role).role}
                            </Typography.Text>
                        </Typography>
                        <MdOutlineAccountCircle size={40} />
                        <Dropdown 
                        overlay={items}
                        >
                            <RiArrowDownSFill size='20px' role='button' />
                        </Dropdown>
                        </> : ''}
                    </div>
                    {
                        token &&
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    }
                </div>
                <div className='csl-confined-header pb-2 d-lg-none d-block w-100'>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="csl-navigation">
                            {
                                token && navItems.map((item, index) => <Nav.Link onClick={() => navigate(`${item.path}`)} className={`csl-nav ${path.includes(item.path) ? 'active' : ''}`}>{item.label}</Nav.Link>)
                            }
                            {
                                token && <>
                                <Nav.Link onClick={() => { logoutHandler(); }} className={`csl-nav`}>{`Log Out`}</Nav.Link>
                                <Nav.Link onClick={() => { changePasswordHandler(); }} className={`csl-nav`}>{`Change Password`}</Nav.Link>
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            <Content className='container content-layout' style={{minHeight: "80vh"}} >
                {props.children}
            </Content>
            <FooterComponent token={token} navItems={navItems} />
        </>
    )
}

export default CslLayout