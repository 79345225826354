import { Button, Form, Input, Space } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import CountryModal from '../../../components/modal/CountryModal'
import USA from "../../../assets/images/USA.png";
import Mexico from "../../../assets/images/Mexico.png";
import Canada from "../../../assets/images/Canada.png";

export default function InputPhone(props) {
    const { size, form, inputData, readOnly } = props;
    const countryModalRef = useRef(null);
    const [currentCountry, setCurrentCountry] = useState({
        code: 1,
        name: "United States",
    });
    const ListCountry = [
        {
            path: USA,
            code: 1,
            name: "United States",
        },
        {
            path: Canada,
            code: 1,
            name: "Canada",
        },
        {
            path: Mexico,
            code: 52,
            name: "Mexico",
        },
    ];
    const watchCountry = Form.useWatch(inputData?.selectForm?.name, form); // Watch the 'requireCOI' field;

    const handleNumberInput = (e) => {
        if (!/^[0-9]*$/.test(e.key)) {
            e.preventDefault(); // Prevent non-numeric input
        }
    };


    const getCurrentCountry = () => {
        return ListCountry.filter((i) => i.name === currentCountry.name)[0];
    };

    useEffect(() => {
        setCurrentCountry(watchCountry?.name ? watchCountry : {
            code: 1,
            name: "United States",
        });

        if (!watchCountry || !watchCountry?.name) {
            form.setFieldValue(inputData?.selectForm?.name, {
                code: 1,
                name: "United States",
            });
        }
    }, [watchCountry])

    return (
        <>
            <Space direction="vertical" size="middle" className="w-100">
                <Space.Compact style={{ width: '100%' }}>
                    <Button
                        style={{ height: "40px" }}
                        onClick={() => {
                            countryModalRef?.current?.open();
                        }}
                    >
                        <div className="d-flex align-items-center" role="button">
                            <img src={getCurrentCountry()?.path}
                                className={
                                    size === "large" ? "country-modal-flag" : "event-country-modal-flag"
                                }
                                alt="Country Flag"
                            />
                            <span
                                className={
                                    size === "large" ? "country-modal-code" : "event-country-modal-code"
                                }
                            >
                                {" "}
                                +{getCurrentCountry()?.code}
                            </span>
                        </div>
                    </Button>
                    <Form.Item noStyle {...inputData?.inputForm}>
                        <Input
                            size={size || "small"}
                            placeholder="Please input your phone number"
                            type="tel"
                            className="custom-phonenumber w-100"
                            maxLength={10}
                            style={{ height: "40px" }}
                            controls={false}
                            disabled={readOnly}
                            onKeyPress={handleNumberInput} // Prevents non-numeric input
                        />
                    </Form.Item>
                </Space.Compact>
            </Space>
            <CountryModal
                ref={countryModalRef}
                size={size || "small"}
                currentCountry={currentCountry}
                setCurrentCountry={(country) => {
                    if (!readOnly) {
                        setCurrentCountry(country)
                        form.setFieldValue(inputData?.selectForm?.name, country);
                    }
                }}
            />
        </>
    )
}
