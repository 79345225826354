import { Button, Form, message, Row } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import EventPreviewModal from '../../components/container/modal/queueModal/EventPreviewModal';
import LoadingOverlay from '../../components/container/loading/LoadingOverlay';
import MessageModalMain from '../../components/container/modal/modalMessage/messageModalMain';
import BackComponent from '../../components/container/backComponent/BackComponent';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import API from '../../utils/request'
import DynamicForm from './components/DynamicForm';
import { requestEventFormSettings } from './RequestEventFormSetting';
import { REQUEST_EVENT_CHANGE_STATUS_URL, REQUEST_EVENT_CREATE_URL, REQUEST_EVENT_DETAIL_URL, REQUEST_EVENT_UPDATE_URL } from '../../config/urls';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { storageKeys } from "../../constants/storage";
import * as Storage from '../../utils/storage';
import { convertDatesRecursively } from '../../utils/utils';
import "./CreateEventRequest.css";

export default function CreateEventRequest() {
    const [form] = Form.useForm();
    let targetAge = ["18-25", "26-46", "47+"];

    const user = useSelector((state) => state.authReducer.user);
    var users = Storage.getString(storageKeys.USER);
    let userUse = user ? user : users


    const navigate = useNavigate();
    const location = useLocation();
    const EventPreviewModalRef = useRef("")
    const messageModalRef = useRef('rememberMe');
    const [data, setData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState();
    const { requestId } = useParams();

    let { detailMode, createMode } = useMemo(() => {
        return {
            detailMode: location?.pathname.includes("/request-event/" + requestId),
            createMode: location?.pathname === "/request-event/form"
        }
    }, [location])

    const handleUpdate = (data) => {
        try {
            setLoading(true)
            let params = getparams(data);

            API.put(REQUEST_EVENT_UPDATE_URL.replace(":id", requestId), params)
                .then(async (res) => {
                    setLoading(false)
                    if (res.status === 200) {
                        messageModalRef.current.showSuccess("Successfully Update The Form!", () => {
                            getDataEventDetail();
                        })
                    } else {
                        messageModalRef.current.showWarning(res.message);
                    }
                })
                .catch((err) => {
                    messageModalRef.current.showWarning(err?.message);
                    setLoading(false)
                })
        } catch (error) {
            setLoading(false)
            message.error('Internal Server Error!')
        } finally {
            setEditMode(false);
        }
    }

    const handleCreate = async (data) => {
        try {
            setLoading(true)
            let params = getparams(data);

            API.post(REQUEST_EVENT_CREATE_URL, params)
                .then(async (res) => {
                    setLoading(false)
                    if (res.status === 201) {
                        messageModalRef.current.showSuccess("Your request has been submitted.", () => {
                            form.resetFields();
                        })
                    } else {
                        messageModalRef.current.showWarning(res.message);
                    }
                })
                .catch((err) => {
                    messageModalRef.current.showWarning(err?.message);
                    setLoading(false)
                })
        } catch (error) {
            setLoading(false)
            message.error('Internal Server Error!')
        }
    }

    const getDataEventDetail = async () => {
        try {
            setLoading(true)
            API.get(REQUEST_EVENT_DETAIL_URL.replace(":id", requestId))
                .then((res) => {
                    if (res.status === 200 && res.data) {
                        const { intakeInformation, complianceSection, vendorSection } = res.data;
                        form.setFieldsValue(convertDatesRecursively({
                            intakeInformation,
                            vendorSection: {
                                ...vendorSection,
                                targetAge: vendorSection?.targetAge.length > 1 ? "All" : vendorSection?.targetAge[0]
                            },
                            complianceSection
                        }));

                        setData({ ...res.data });
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                })
        } catch (error) {
            setLoading(false)
            message.error('Internal Server Error!')
        }
    }
    useEffect(() => {
        if (detailMode) {
            requestId && getDataEventDetail()
        }
    }, [requestId]);


    const getparams = (dataItem) => {
        const { vendorSection, intakeInformation } = dataItem;
        const totalContractAmount = String(intakeInformation?.totalContractAmount).replace(/[^0-9.]/g, '');

        let params = {
            ...dataItem,
            vendorSection: {
                ...vendorSection,
                eventStartDate: dayjs(vendorSection?.eventStartDate).format("YYYY/MM/DD"),
                eventEndDate: dayjs(vendorSection?.eventEndDate).format("YYYY/MM/DD"),
                targetAge: vendorSection?.targetAge === "All" ? targetAge : [vendorSection?.targetAge]
            },
            intakeInformation: {
                ...intakeInformation,
                totalContractAmount: totalContractAmount,
            }
        }
        return params
    }

    const onFinishFailed = (errorInfo) => {
        form.scrollToField(errorInfo.errorFields[0].name);
    };

    const changeStatus = async (status) => {
        messageModalRef.current.showWarningConfirm(`Are you sure you want to ${status} this form?`, async () => {
            try {
                setLoading(true)

                API.put(REQUEST_EVENT_CHANGE_STATUS_URL.replace(":id", requestId), { status: status })
                    .then(async (res) => {
                        setLoading(false)
                        if (res.status === 200) {
                            messageModalRef.current.showSuccess("Form has been " + status + "!", () => {
                                navigate(`/request-event`, { state: {}, replace: true });
                            })
                        } else {
                            messageModalRef.current.showWarning(res.message);
                        }
                    })
                    .catch((err) => {
                        messageModalRef.current.showWarning(err?.message);
                        setLoading(false)
                    })
            } catch (error) {
                setLoading(false)
                message.error('Internal Server Error!')
            }
        }, "", true);
    }

    const getBtnActions = () => {
        const btn = [];

        if (!createMode && data?.status === "new") {
            if (editMode && detailMode && userUse) {
                btn.push(<Button className='btn-width-130' onClick={() => setEditMode(!editMode)} variant="primary" size="sm" style={{ background: "none", borderColor: "#4096ff", color: "#4096ff" }}>
                    <span>
                        Cancel
                    </span>
                </Button>)
            }

            if (editMode && detailMode && userUse) {
                btn.push(<Button className='btn-width-130' htmlType="submit" variant="primary" size="sm" style={{ background: "none", borderColor: "rgb(33, 194, 115)", color: "rgb(33, 194, 115)" }}>
                    <span>Update</span>
                </Button>)
            }


            if (!editMode && detailMode && userUse) {
                btn.push(<Button className="btn-width-130" onClick={() => changeStatus("rejected")} variant="primary" size="sm" style={{ background: "none", borderColor: "#fc1921", color: "#fc1921" }}>
                    <span>Reject</span>
                </Button>)
            }

            if (!editMode && detailMode && userUse) {
                btn.push(<Button className='btn-width-130' onClick={() => changeStatus("approved")} variant="primary" size="sm" style={{ background: "none", borderColor: "rgb(33, 194, 115)", color: "rgb(33, 194, 115)" }}>
                    <span>Approve</span>
                </Button>)
            }
            
            if (!editMode && detailMode && userUse) {
                btn.push(<Button className='btn-width-130' onClick={() => setEditMode(!editMode)} variant="primary" size="sm" style={{ background: "none", borderColor: "#4096ff", color: "#4096ff" }}>
                    <span>
                        Edit
                    </span>
                </Button>)
            }
        }

        if (createMode) {
            btn.push(<Button className='btn-bg-blue' type="primary" htmlType="submit">
                Submit
            </Button>);
        }

        return btn;
    }

    return (
        <div className="width-100 m-auto h-100 pt-2-">
            {
                userUse &&
                <BackComponent
                    onClick={() => {
                        navigate(`/request-event`,
                            { state: {}, replace: true }
                        );
                    }}
                    title={"Event Requests"}
                />
            }

            <Form
                layout='vertical'
                rootClassName='read-only-text-black'
                requiredMark={false}
                form={form}
                onFinishFailed={onFinishFailed}
                onFinish={(data) => {
                    if (userUse && editMode && !createMode) {
                        handleUpdate(data)
                    } else if (createMode) {
                        handleCreate(data)
                    }
                }}>
                <div className="w-100 h-100- mt-2">
                    <div className='w-100 pt-2 div-form'>
                        <Row gutter={[20, 20]}>
                            {
                                requestEventFormSettings?.map(item => {
                                    return <DynamicForm readOnly={!editMode && detailMode} formData={item} form={form} />
                                })
                            }
                        </Row>
                    </div>
                </div>

                <div className="div-form w-100 h-100 mt-3">
                    <div className='d-flex flex-wrap gap-2 align-items-center justify-content-around'>
                        {getBtnActions()}
                    </div>
                </div>
            </Form>

            <EventPreviewModal
                ref={EventPreviewModalRef}
            />
            {loading ? <LoadingOverlay /> : null}
            <MessageModalMain textCentered ref={messageModalRef} />
        </div>
    )
}
