// Auth
export const LOGIN_URL = '/auth/login';
export const MAIN_USER_URL = '/user'

// User List
export const USER_LIST_URL = '/user/lists'
export const USER_CREATE_URL = '/user/create'
export const USER_DELETE_URL = '/user/delete'
export const USER_SENT_RESET_PASSWORD_URL = '/user/send-reset-password'
export const USER_BY_ID = '/user/get-by-id'

//Event
export const EVENT_LIST_URL = '/event/lists'
export const EVENT_GET_URL = '/event/get'
export const EVENT_CREATE_URL = '/event/create'
export const EVENT_UPDATE_URL = '/event/update'

// Update Status 
export const UPDATE_PRIORITY = '/user/update-priority'
export const UPDATE_STATUS = '/user/status'
export const WARNIN_MESSAGE = '/user/update-warnning'
export const DOWNLOAD_PDF = '/user/download-pdf'
export const UPLOAD_FILE = '/event/upload-image';

// Reset Password Admin
export const CHECK_TOKEN_SENDEMIAL = '/user/reset-password'
export const RESET_PASSWORD = '/user/reset-password'


//EVENT SUBMIT FORM
export const EVENT_SUBMIT = '/form-submission/submit';
export const EVENT_CHECK_SCAN = "/form-submission/get-event";
export const EVENT_SCAN = '/form-submission/scanned';
export const EVENT_FORMSUBMISSION_SCAN_URL = '/form-submission/lists';
export const EVENT_FORMSUBMISSION_EXPORT = '/form-submission/export';
export const EVENT_CHANGE_STATUS = "/event/status";
export const EVENT_WINNER = "/form-submission/winner";
export const EVENT_RANDOM_WINNER = "/form-submission/random-winner";
export const EVENT_REMOVE_WINNER = "/form-submission/remove-winner";

//Dashboard
export const DASHBOARD = "/dashboard";
export const DASHBOARD_LIST = "/dashboard/lists";

// REQUEST EVENT 
export const REQUEST_EVENT_LIST_URL = '/request-event-form/list'
export const REQUEST_EVENT_DETAIL_URL = '/request-event-form/:id'
export const REQUEST_EVENT_UPDATE_URL = '/request-event-form/:id'
export const REQUEST_EVENT_CHANGE_STATUS_URL = '/request-event-form/:id/status'
export const REQUEST_EVENT_CREATE_URL = '/request-event-form/create'