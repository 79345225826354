import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ArrowRight from '../../../assets/svgicons/ArrowRight';
import { formatDateSecondary } from '../../../utils/dateFormatUtils';
import BackRightBtn from '../../../assets/svgicons/BackRightBtn';
import { formatNumber } from '../../../utils/string';
import CopySVG from '../../../assets/svgicons/copySVG';
import IconScan from '../../../assets/svgicons/Scan';
import IconFormSubmit from '../../../assets/svgicons/FormSubmit';
import Paragraph from 'antd/es/typography/Paragraph';
import { useState } from 'react';

function RequestEventCardComponent({ data, handleDetail, handleCopyLink }) {
    const [expanded, setExpanded] = useState(false);
    return (
        <Card>
            <Card.Header className='pt-3 pb-3' style={{ borderBottom: "1px dashed rgba(0, 0, 0, 0.175)" }}>
                <div className='row'>
                    <div className='col-9'>
                        <div className='row'>
                            <div className='col-6 d-flex align-items-center'>
                                <span className='pe-2 ps-2 text-label'>Recipient Name: </span> <span style={{ fontWeight: "600" }}>{data?.intakeInformation?.recipientFirstName + " " + data?.intakeInformation?.recipientLastName}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-3 d-flex align-items-center- justify-content-end text-end align-items-center'>
                        <span
                            onClick={() => handleDetail && handleDetail(data)}
                            className='d-flex' style={{ background: "none", color: "#0d6efd", cursor: "pointer", justifyContent: "end" }}>
                            <span className='text-label-link' style={{ background: "none", color: "#0d6efd", cursor: "pointer" }}>View detail</span>
                            <span className='ms-1 d-flex align-items-center'><ArrowRight stroke={"rgb(13, 110, 253)"} /></span>
                        </span>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <div className='row mt-1'>
                    <div className='col-2'>
                        <Card.Text className='text-label'>
                            Organization Name
                        </Card.Text>
                    </div>
                    <div className='col-7'>
                        <Card.Text className='text-labe2'>
                            {(data && data?.vendorSection?.organizationName) || "N/A"}
                        </Card.Text>
                    </div>
                </div>
                <div className='row mt-1'>
                    <div className='col-2'>
                        <Card.Text className='text-label'>
                            Request number
                        </Card.Text>
                    </div>
                    <div className='col-7'>
                        <Card.Text className='text-labe2'>
                            {data && data?.requestId}
                        </Card.Text>
                    </div>
                </div>
                <div className='row mt-1'>
                    <div className='col-2'>
                        <Card.Text className='text-label'>
                            Request center
                        </Card.Text>
                    </div>
                    <div className='col-7'>
                        <Card.Text className='text-label2'>
                            <Paragraph
                                ellipsis={{
                                    rows: 6,
                                    expandable: 'collapsible',
                                    expanded,
                                    onExpand: (_, info) => setExpanded(info.expanded),
                                    symbol: expanded ? "Load Less" : "Load More"
                                }}
                                style={{ fontSize: "inherit" }}
                            >
                                {data && data?.centerName && data?.centerName.map(ctn => <p className='text-label2 m-0'>
                                    {ctn}
                                </p>)}
                            </Paragraph>
                        </Card.Text>
                    </div>
                </div>
                <div className='row mt-1'>
                    <div className='col-2'>
                        <Card.Text className='text-label'>
                            Event date
                        </Card.Text>
                    </div>
                    <div className='col-7'>
                        <Card.Text className='text-label2'>
                            <span>{data && data && data?.vendorSection?.eventStartDate ? formatDateSecondary(data?.vendorSection?.eventStartDate) : ''}</span> <span className='ps-2 pe-2'><BackRightBtn opacity={"0.4"} width={"1rem"} height={"1rem"} /></span> <span>{data && data && data?.vendorSection?.eventEndDate ? formatDateSecondary(data?.vendorSection?.eventEndDate) : ""}</span>
                        </Card.Text>
                    </div>
                    <div className='col-3 text-end'>
                        <Button
                            onClick={() => handleCopyLink && handleCopyLink(`${window.location.href}/${data?.requestId}`)}
                            variant="primary" size="sm" style={{ background: "none", color: "#0d6efd" }}>
                            <CopySVG width={"1.3rem"} height={"1.3rem"} />
                            <span className='ps-1'>Copy Link</span>
                        </Button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default RequestEventCardComponent;