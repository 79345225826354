import React, { useEffect, useMemo, useState } from 'react'
import SelectMultiCustom from '../../../components/select/SelectMultiCustom'
import { Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Radio, Row, Select } from 'antd'
import { CenterObject } from '../../../utils/checkCenterLocation';
import { API_ENDPOINT_LOCATION } from '../../../config/api';
import API from '../../../utils/request'
import InputCurrency from './InputCurrency';
import InputPhone from './InputPhone';
import dayjs from 'dayjs';

export default function DynamicForm(props) {
    const { readOnly, formData, form } = props;

    const [centerData, setCenterData] = useState([])

    const dependenciesField = Form.useWatch(formData?.dependenciesField?.key, form); // Watch the 'requireCOI' field;
    const currentValue = Form.useWatch(formData?.form?.name, form); // Watch the 'requireCOI' field;

    const getDataCenter = async () => {
        try {
            API.post(API_ENDPOINT_LOCATION, {
                "serviceName": "location",
                "serviceType": "getSavableLocations"
            })
                .then((res) => {
                    if (res.status === 200) {
                        setCenterData(res.locations ? res.locations.map(it => ({
                            ...it,
                            label: `CSL Plasma, ${it.name}`,
                            value: it.locationID
                        })) : [])
                    }
                })
                .catch((err) => {
                })
        } catch (error) {
        }
    }

    useEffect(() => {
        if (formData?.type === "select-center") {
            getDataCenter()
        }
    }, [formData]);


    const renderInputByType = (type, inputData) => {
        switch (type) {
            case "phone":
                return <InputPhone
                    size={"middle"}
                    inputData={inputData}
                    form={form}
                    readOnly={readOnly}
                />
            case "text":
                return <Input style={{ height: "40px" }} size={"middle"} disabled={readOnly} placeholder={inputData?.placeholder} />
            case "textarea":
                return <Input.TextArea disabled={readOnly} placeholder={inputData?.placeholder} />
            case "date":
                return <DatePicker
                    style={{ height: "40px" }}
                    disabled={readOnly}
                    className="w-100"
                    placeholder={inputData?.placeholder}
                />
            case "radio":
                return <Radio.Group disabled={readOnly}>
                    {inputData?.options?.map((option) => (
                        <Radio key={option.value} value={option.value} style={{ display: "block" }}>
                            {option.label}
                        </Radio>
                    ))}
                </Radio.Group>
            case "checkbox.group":
                return <Checkbox.Group disabled={readOnly} style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                    {inputData?.options?.map((option) => (
                        <Checkbox key={option.value} value={option.value}>
                            {option.label}
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            case "number":
                return <InputNumber
                    style={{ height: "40px" }}
                    disabled={readOnly}
                    className='w-100 inputnumber-align-center'
                    placeholder={inputData?.placeholder}
                />
            case "money":
                return <InputCurrency
                    height={"40px"}
                    disabled={readOnly}
                    placeholder={inputData?.placeholder}
                    value={currentValue}
                    onChange={(value) => form.setFieldValue(inputData?.form?.name, value)}
                />
            case "select":
                return <Select
                    disabled={readOnly}
                    placeholder={inputData?.placeholder}
                    options={inputData?.options || []}
                    style={{ height: "40px" }}
                />
            case "select-center":
                return <SelectMultiCustom
                    currentSelect={
                        currentValue?.map(ct => ct && CenterObject(ct, centerData)) || []
                    }
                    onSelected={(value) => {
                        const locationIds = value.map(ct => `${JSON.parse(ct).locationID}`);
                        form.setFieldValue(inputData?.form?.name, [...locationIds]);
                    }}
                    maxTagCount={"responsive"}
                    disabled={readOnly}
                    height={"40px"}
                    minWidth={200}
                    className={`w-100 ant-select-wrapper-height-full`}
                    centerData={centerData}
                />
            default:
        }
    }

    const renderLabel = (label) => {
        return <div style={{ fontWeight: 600 }}>
            {label} {(formData?.required && !readOnly) && <span class="require_cus">*</span>}
        </div>
    }

    const rules = useMemo(() => {
        const rules = [];
        if (formData?.required) {
            rules.push({ required: true, message: "" })
        }
        return [...rules, ...formData?.form?.rules || []]
    }, [formData])

    if (formData?.type === "divider") {
        return <Col span={24}>
            <div style={{ fontWeight: 800, fontSize: "16px" }}>
                {formData?.label}
            </div>
        </Col>
    }

    if (formData?.type === "label") {
        return <div>
            <h3 class="event-name">{formData?.label}</h3>
            {
                formData?.subForm?.length > 0 &&
                <Row gutter={[20, 20]}>
                    {
                        formData?.subForms?.map(item => {
                            return <DynamicForm readOnly={readOnly} formData={item} form={form} />
                        })
                    }
                </Row>
            }
        </div>
    }

    const marginLeft = (formData?.subNumber || 0) * 30;

    const style = { borderRadius: "0.25rem", boxShadow: "0 3px 5px #0000001a", border: "1px solid rgba(0,0,0,0.125)", marginLeft: marginLeft };

    return (
        formData?.dependenciesField?.onHide(dependenciesField)
            ?
            <></>
            :
            <Col {...formData?.col}>
                <div className='bg-white' style={style}>
                    <div className='rounded-lg submit-form-padding'>
                        <Form.Item {...formData?.form} label={renderLabel(formData?.form?.label)} rules={rules} className='mb-0'>
                            {renderInputByType(formData?.type, formData)}
                        </Form.Item>
                        {formData?.bottomTitle?.onShowBottom(currentValue)}
                    </div>


                    {
                        formData?.subForm?.onRender(currentValue)?.length > 0 &&
                        <div className='w-100 ps-3 pe-3 pb-3'>
                            <Row gutter={[20, 20]}>
                                {
                                    formData?.subForm?.onRender(currentValue)?.map(item => {
                                        return <DynamicForm readOnly={readOnly} formData={item} form={form} />
                                    })
                                }
                            </Row>
                        </div>
                    }
                </div>
            </Col>
    )
}
